const accountLink = document.querySelector('.header__acc.link');
const profileTooltip = document.querySelector('.profile-tooltip');
const active = 'active';
const leaveTimeout = 500;
let timer;

const hoverDelay = () => {
  if (!accountLink) {
    return;
  }

  accountLink.addEventListener('mouseenter', () => {
    accountLink.classList.add(active);
  });

  accountLink.addEventListener('mouseleave', () => {
    timer = setTimeout(() => {
      accountLink.classList.remove(active);
    }, leaveTimeout);
  });

  profileTooltip.addEventListener('mouseenter', () => {
    clearTimeout(timer);
  });

  profileTooltip.addEventListener('mouseleave', () => {
    accountLink.classList.remove(active);
  });
};

export {hoverDelay};
