const promoSliderElement = document.querySelector('.promo-slider');
let swiper;

const initSwiper = (slider) => {
// eslint-disable-next-line no-undef
  swiper = new Swiper(slider, {
    navigation: {
      nextEl: '.promo-slider__button--next',
      prevEl: '.promo-slider__button--prev',
    },
    pagination: {
      el: '.swiper-pagination',
    },
    autoplay: {
      delay: 5000,
    },
    loop: true,
  });
  return swiper;
};

const initPromoSlider = () => {
  if (!promoSliderElement) {
    return;
  }

  initSwiper(promoSliderElement);
};

export {initPromoSlider};
