import {closeModal, setupModal, openModal} from '../modals/modal';

import FormsValidate from './form-validate';
const formWrappers = document.querySelectorAll('[data-validate]');

const resetForm = (form) => {
  setTimeout(() => {
    window.clearForm(form);
  }, 1000);
};

const baseSuccessCallback = (e) => {
  e.preventDefault();
  resetForm(e.target);
};

const baseErrorCallback = (e) => {
  e.preventDefault();
};

// Показ сообщения об успешной отправки формы

const showMessageOnSuccessSubmit = (e) => {
  e.preventDefault();
  resetForm(e.target);
  const subscriptionBlock = document.querySelector('.subscription__wrapper');
  if (!subscriptionBlock) {
    return;
  }
  subscriptionBlock.classList.add('subscription__wrapper--is-success-submit');
};

const showSuccessMessage = (e) => {
  e.preventDefault();
  resetForm(e.target);

  closeModal(document.querySelector('.modal--callback-form'));
  setupModal(document.querySelector('.modal--callback-success'));
  setTimeout(() => {
    openModal(document.querySelector('.modal--callback-success'));
  }, 350);
};

const callbacks = {
  base: {
    successCallback: baseSuccessCallback,
    errorCallback: baseErrorCallback,
  },
  submit: {
    successCallback: showMessageOnSuccessSubmit,
    errorCallback: baseErrorCallback,
  },
  callbackFormSuccess: {
    successCallback: showSuccessMessage,
    errorCallback: baseErrorCallback,
  },
};

export const initFormValidate = () => {
  if (formWrappers.length) {
    formWrappers.forEach((wrapper) => {
      let callback = wrapper.dataset.callback;
      if (!callback) {
        callback = 'base';
      }

      const formValidate = new FormsValidate(wrapper, callbacks[callback]);

      return formValidate.init();
    });
  }
};

export default initFormValidate;
