// import {closeMenu} from './init-mobile-menu';

const breakpoint = window.matchMedia('(min-width:959px)');

const mobileCatalogOpenButton = document.querySelector('.button--open-catalog');
const catalog = document.querySelector('.catalog');
const catalogMenuList = document.querySelector('.catalog__list');
const catalogItems = document.querySelectorAll('.catalog__item');
const catalogBlocks = document.querySelectorAll('.catalog__block');
const catalogSubBlocks = document.querySelectorAll('.catalog__subblock');
const catalogTitle = document.querySelector('.catalog__title--mobile');
const buttonPrev = document.querySelector('.button--prev-header');
const closeButton = document.querySelector('.catalog .close-button');
const buttonCatalog = document.querySelector('.button--catalog');
const mobileMenu = document.querySelector('.mobile-menu');

const activeMenu = 'active-menu';
const hiddenPrev = 'hidden-prev';

const catalogTitleText = 'Каталог товаров';

let nestingList = [];

const openCatalog = () => {
  if (breakpoint.matches) {
    return;
  }

  let activeBlock = catalogMenuList.querySelector(`.catalog__block.${activeMenu}`);
  if (activeBlock) {
    activeBlock.classList.remove(activeMenu);
    catalogMenuList.querySelector(`.catalog__link.${activeMenu}`).classList.remove(activeMenu);
  }

  catalog.classList.add(activeMenu);
};

const closeButtonHandler = (preventScrollLock) => {
  if (breakpoint.matches) {
    return;
  }

  catalog.classList.remove(activeMenu);
  catalogTitle.innerHTML = catalogTitleText;
  buttonPrev.classList.add(hiddenPrev);
  nestingList = [];

  document.querySelectorAll(`.catalog__subblock-list.${activeMenu}`).forEach((item) => item.classList.remove(activeMenu));
  document.querySelectorAll(`.catalog__block.${activeMenu}`).forEach((item) => item.classList.remove(activeMenu));

  mobileMenu.classList.remove('active');
  buttonCatalog.ariaPressed = 'false';

  if (!preventScrollLock) {
    setTimeout(() => {
      window.enableBodyScroll(mobileMenu);
    }, 300);
  }
};

const prevButtonHandler = () => {
  let lastItem = nestingList.pop();

  if (lastItem.classList.contains('catalog__subblock')) {
    let innerList = lastItem.querySelector('ul');
    if (innerList) {
      innerList.classList.remove(activeMenu);
    }
  } else if (lastItem.classList.contains('catalog__block')) {
    lastItem.classList.remove(activeMenu);
    buttonPrev.classList.add(hiddenPrev);
  }

  let prevCategory = nestingList[nestingList.length - 1];
  if (prevCategory) {
    let title = prevCategory.querySelector('.catalog__block-title').innerHTML;
    catalogTitle.innerHTML = title;
  } else {
    catalogTitle.innerHTML = catalogTitleText;
    buttonPrev.classList.add(hiddenPrev);
  }
};

const catalogItemHandler = (item, i) => {
  let catalogLink = item.querySelector('.catalog__link');
  if (catalogLink && catalogLink.classList.contains('catalog__link--with-submenu')) {
    nestingList.push(catalogBlocks[i]);

    catalogBlocks[i].classList.add(activeMenu);
    buttonPrev.classList.remove(hiddenPrev);

    catalogTitle.innerHTML = item.querySelector('a.link').getAttribute('aria-label');
  }
};

const subItemHandler = (item) => {
  let innerList = item.querySelector('ul');
  let innerLink = item.querySelector('a');

  if (innerList) {
    nestingList.push(item);

    innerList.classList.add(activeMenu);
    catalogTitle.innerHTML = innerLink.getAttribute('aria-label');
  }
};

const initMobileCatalog = () => {
  if (!mobileCatalogOpenButton) {
    return;
  }

  mobileCatalogOpenButton.addEventListener('click', () => {
    openCatalog();
  });

  catalogItems.forEach((item, i) => {
    item.addEventListener('click', () => catalogItemHandler(item, i));
  });

  catalogSubBlocks.forEach((item) => {
    item.addEventListener('click', () => subItemHandler(item));
  });

  closeButton.addEventListener('click', () => closeButtonHandler());

  buttonPrev.addEventListener('click', () => prevButtonHandler());
};

export {initMobileCatalog, closeButtonHandler};
