const forms = document.querySelectorAll('.js-num-form');
const plusBtns = document.querySelectorAll('.button--plus');
const minusBtns = document.querySelectorAll('.button--minus');
const inputs = document.querySelectorAll('.js-num input');

const initCount = () => {
  if (!forms) {
    return;
  }

  plusBtns.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      e.preventDefault();
      const parent = btn.closest('[data-type="parent"]');
      const input = parent.querySelector('input');
      const value = Number(input.value);
      let result;

      if (parent.querySelector('.button--minus').hasAttribute('disabled')) {
        parent.querySelector('.button--minus').disabled = false;
        result = value + 1;
        input.value = result;
      } else {
        result = value + 1;
        input.value = result;
      }
    });
  });

  minusBtns.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      e.preventDefault();
      const parent = btn.closest('[data-type="parent"]');
      const input = parent.querySelector('input');
      const value = Number(input.value);

      const result = value - 1;
      input.value = result;

      if (result <= 1) {
        input.value = 1;
        btn.disabled = true;
      }
    });
  });

  inputs.forEach((input) => {
    input.addEventListener('focus', (e) => {
      e.preventDefault();
      input.value = '';
    });

    input.addEventListener('click', (e) => {
      e.preventDefault();
      input.value = '';
    });

    input.addEventListener('blur', (e) => {
      e.preventDefault();
      if (input.value === '') {
        input.value = 1;
      }
    });

    input.addEventListener('input', (e) => {
      e.preventDefault();
      const value = Number(input.value);
      if (value < 1) {
        input.value = 1;
      }
    });
  });
};

export {initCount};
