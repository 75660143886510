const accBodyStyle = (fSelect, accBody) => {
  if (fSelect && accBody) {
    const toggleClass = () => {
      accBody.classList.toggle('overflow-none');
    }
    
    fSelect.addEventListener('click', e => {
      e.stopPropagation();
    
      toggleClass();
    });
    
    document.addEventListener('click', e => {
      let target = e.target;
      let its_acc = target == accBody || accBody.contains(target);
      let its_select = target == fSelect;
      let select_is_active = accBody.classList.contains('overflow-none');
      
      if (!its_acc && !its_select && select_is_active) {
        toggleClass();
      }
    })
  }
}

let accBody = document.querySelectorAll('.accordeon-body');

accBody.forEach((accBody) => {
  let fSelect = accBody.querySelectorAll('.favorites-select');

  fSelect.forEach((fSelect) => {
    accBodyStyle(fSelect, accBody);
  })  
});