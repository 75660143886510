const header = document.querySelector('.header');

if (header) {
  const searchButton = document.querySelector('.button--search');
  const searchInput = document.querySelector('.custom-input--christi-header input');
  const buttonCloseSearch = document.querySelector('.button--search-mobile');
  const buttonCatalog = document.querySelector('.button--catalog');
  const breakpoint = window.matchMedia('(max-width:639px)');
  const page = document.querySelector('.page');
  const headerMain = document.querySelector('.header__main');
  const active = 'active';

  const openSearch = () => {
    searchButton.addEventListener('click', () => {
      searchInput.style.display = 'block';
      buttonCloseSearch.style.display = 'block';
      buttonCatalog.style.top = '-104px';
      page.style.paddingTop = '148px';
      headerMain.style.paddingBottom = '20px';
      headerMain.style.rowGap = '15px';

      searchButton.classList.add(active);
    });
  };

  const closeSearch = () => {
    buttonCloseSearch.addEventListener('click', () => {
      searchInput.style.display = 'none';
      buttonCloseSearch.style.display = 'none';
      buttonCatalog.style.top = '-36px';
      page.style.paddingTop = '80px';
      headerMain.style.paddingBottom = '0';
      headerMain.style.rowGap = '7px';

      searchButton.classList.remove(active);
    });
  };

  // изменение типа кнопки на мобилке
  const changeButtonType = () => {
    searchButton.setAttribute('type', 'button');
  };

  if (breakpoint.matches) {
    changeButtonType();
    openSearch();
    closeSearch();
  }
}
