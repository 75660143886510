// import {ieFix} from './utils/ie-fix';
// import {polyfillObjectFit} from './utils/polyfill-object-fit';

import {iosVhFix} from './utils/ios-vh-fix';

import {initModals} from './modules/modals/init-modals';
import {initFormValidate} from './modules/form/init-form-validate';
import {initFooterAccordions} from './modules/init-footer-accordion';
import {initMobileMenu} from './modules/init-mobile-menu';
import {initNumInputs} from './modules/init-num-inputs';
import {initCount} from './modules/init-count';
import {initDropdown} from './modules/init-dropdown';
import {initProductSlider} from './modules/init-product-slider';
import {initPromoSlider} from './modules/init-promo-slider.js';
import {initService} from './modules/init-service';
import {initCityPopup} from './modules/init-city-popup';
import {initMobileCatalog} from './modules/init-mobile-catalog';
import {initMobileSearch} from './modules/init-mobile-search';
import {hoverDelay} from './modules/hover-delay';
import {initCardPopup} from './modules/init-card-popup';
import {initActiveBtn} from './modules/init-active-btn';
import {initCatalog} from './modules/init-catalog';
import './modules/form/init-form-validate';
import './modules/form/custom-select';
import './modules/form/custom-select-acc';
import './modules/init-mobile-search';
import {initCallbackForm} from './modules/init-callback-form';
import {filterOpen} from './modules/filter-open';
import {scrollTop} from './modules/scroll-top';
import {rangeSlider} from './modules/range-slider';
import {simpleAccordeon} from './modules/simple-accordeon';
import {simpleGallery} from './modules/simple-gallery';
import {fixedBlock} from './modules/fixed-block';
import {tabs} from './modules/tabs';


// ---------------------------------

window.addEventListener('DOMContentLoaded', () => {

  // Utils
  // ---------------------------------

  // только если ie11
  // ieFix();
  // https://github.com/fregante/object-fit-images
  // polyfillObjectFit();

  iosVhFix();

  // Modules
  // ---------------------------------

  initNumInputs();
  initCount();

  // все скрипты должны быть в обработчике 'DOMContentLoaded', но не все в 'load'
  // в load следует добавить скрипты, не участвующие в работе первого экрана
  window.addEventListener('load', () => {    
    initModals();
    initFormValidate();
    initMobileMenu();
    initDropdown();
    initProductSlider();
    initFooterAccordions();
    initPromoSlider();
    initService();
    initCityPopup();
    initMobileCatalog();
    hoverDelay();
    initCardPopup();
    initActiveBtn();
    initCatalog();
    initCallbackForm();
    tabs();
    simpleAccordeon();
    simpleGallery();
    fixedBlock();
    scrollTop();
    filterOpen();
    rangeSlider();
  });
});

// ---------------------------------

// используйте .closest(el)

// для адаптивного JS используейтся matchMedia и addListener
// const breakpoint = window.matchMedia(`(min-width:1024px)`);
// const breakpointChecker = () => {
//   if (breakpoint.matches) {
//   } else {
//   }
// };
// breakpoint.addListener(breakpointChecker);
// breakpointChecker();

// привязывайте js не на бэм, а на data-cookie

// выносим все в data-attr - url до пинов карты, настройки автопрокрутки, url к json и т.д.
