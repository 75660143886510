const simpleGallery = () => {
  var mainImg = document.querySelector(".main-img");
  var selectImg = document.querySelectorAll(".select-img");
  var i;

  for (i = 0; i < selectImg.length; i++) {
    selectImg[i].addEventListener("click", function () {
      mainImg.src = this.src;
      for (var j = 0; j < selectImg.length; j++) {
        selectImg[j].classList.remove("active");
      }
      this.classList.add("active");
    });
  }
};

export {
  simpleGallery
};
