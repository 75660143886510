const buttonCatalog = document.querySelector('.button--catalog');
const breakpoint = window.matchMedia('(min-width:959px)');
const catalog = document.querySelector('.catalog');
const catalogCloseButton = document.querySelector('.catalog .close-button');
const catalogLinks = document.querySelectorAll('.catalog__link');
const catalogBlocks = document.querySelectorAll('.catalog__block');

const activeMenu = 'active-menu';
const active = 'active';

const openCatalog = (preventScrollLock) => {
  buttonCatalog.ariaPressed = 'true';
  catalog.classList.add('active');

  let firstItem = document.querySelector('.catalog');
  if (firstItem) {
    firstItem.querySelector('.catalog__link').classList.add(active);
    firstItem.querySelector('.catalog__block').classList.add(activeMenu);
  }

  if (!preventScrollLock) {
    window.disableBodyScroll(catalog, {
      reserveScrollBarGap: true,
    });
  }
};

const closeCatalog = (preventScrollLock) => {
  if (!breakpoint.matches) {
    return;
  }

  buttonCatalog.ariaPressed = 'false';
  catalog.classList.remove('active');

  let activeBlock = document.querySelector(`.catalog__block.${activeMenu}`);
  if (activeBlock) {
    activeBlock.classList.remove(activeMenu);
    document.querySelector(`.catalog__link.${active}`).classList.remove(active);
  }

  if (!preventScrollLock) {
    setTimeout(() => {
      window.enableBodyScroll(catalog);
    }, 300);
  }
};

const closeCatalogOnEsc = (evt) => {
  const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';

  if (isEscKey && catalog.classList.contains('active')) {
    evt.preventDefault();
    closeCatalog(catalog);
  }
};

const closeCatalogOnResize = () => {
  closeCatalog();
};

const openCategory = (link, i) => {
  if (!breakpoint.matches) {
    return;
  }

  const lastActiveLink = document.querySelector('.catalog__link.active');
  const lastActiveBlock = document.querySelector('.catalog__block.active-menu');
  lastActiveLink.classList.remove('active');
  lastActiveBlock.classList.remove('active-menu');

  link.classList.add('active');
  catalogBlocks[i].classList.add('active-menu');
};

const initCatalog = () => {
  if (!buttonCatalog) {
    return;
  }

  buttonCatalog.addEventListener('click', () => {
    if (breakpoint.matches) {
      if (buttonCatalog.ariaPressed === 'true') {
        closeCatalog();
      } else {
        openCatalog();
      }
    }
  });

  catalogLinks.forEach((item, i) => {
    item.addEventListener('click', () => {
      openCategory(item, i);
    });
  });

  catalogCloseButton.addEventListener('click', () => {
    closeCatalog();
  });

  document.addEventListener('keydown', (evt) => {
    closeCatalogOnEsc(evt);
  });

  breakpoint.addListener(closeCatalogOnResize);
};

export {initCatalog};
