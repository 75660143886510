const initService = () => {
  const icons = document.querySelectorAll('.js-service');

  if (!icons) {
    return;
  }

  icons.forEach((icon) => {
    icon.addEventListener('click', () => {

      if (icon.classList.contains('js-service--compare')) {
        icon.classList.toggle('is-active');
      } else if (icon.classList.contains('js-service--hover')) {
        const parent = icon.closest('.js-parent-service');
        const redHeard = parent.querySelector('.js-service--active');
        redHeard.style.display = 'inline';
        redHeard.classList.add('is-active');
        icon.style.display = 'none';
      } else if (icon.classList.contains('js-service--active')) {
        const parent = icon.closest('.js-parent-service');
        const heard = parent.querySelector('.js-service--hover');
        heard.style.display = 'inline';
        icon.style.display = 'none';
      }

    });
  });
};

export {initService};
