import {openModal, closeModal} from './modals/modal';

const cityPopup = document.querySelector('.city-popup');
const cityTooltip = document.querySelector('.city-tooltip');
const noButton = document.querySelector('.city-tooltip__button--no');
const yesButton = document.querySelector('.city-tooltip__button--yes');
const popupCloseButton = document.querySelector('.city-popup .close-button');
const allCitiesLabels = document.querySelectorAll('.city-popup__main-link');
const modalCityTitle = document.querySelector('.city-popup__selected-city');
const headerCityTitle = document.querySelector('.header__select-text');
const headerCityLink = document.querySelector('.header__select-city');
const hidden = 'hidden';
const active = 'active';

const initCityPopup = () => {
  if (!cityPopup) {
    return;
  }

  // выбор города в попапе
  allCitiesLabels.forEach((cityItem) => {
    cityItem.addEventListener('click', () => {
      const allActiveItems = document.querySelectorAll('.city-popup__main-link.active');

      if (cityItem.innerHTML) {
        modalCityTitle.innerHTML = cityItem.innerHTML;
        headerCityTitle.innerHTML = cityItem.innerHTML;
        headerCityLink.ariaLabel = cityItem.innerHTML;
        allActiveItems.forEach((oldItem) => {
          oldItem.classList.remove(active);
        });
        cityItem.classList.add(active);
        closeModal(cityPopup);
        cityTooltip.classList.add(hidden);
      }
    });
  });

  // закрытие тултипа по кнопке "да"
  yesButton.addEventListener('click', () => {
    cityTooltip.classList.add(hidden);
  });

  // открытие попапа по кнопке "нет"
  noButton.addEventListener('click', () => {
    openModal(cityPopup);
  });

  // открытие попапа по названию города в шапке
  headerCityLink.addEventListener('click', () => {
    openModal(cityPopup);
  });

  // закрытие попапа по кнопке крестика
  popupCloseButton.addEventListener('click', () => {
    closeModal(cityPopup);
  });

  // закрытие попапа по esc
  document.addEventListener('keydown', (evt) => {
    if (evt.key === 'Escape' || evt.key === 'Esc') {
      closeModal(cityPopup);
    }
  });
};

export {initCityPopup};
