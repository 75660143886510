const numInputs = document.querySelectorAll('.js-num input');
const numInputsRegEx = /[^\d]/g;

const initNumInputs = () => {
  if (numInputs.length) {
    numInputs.forEach((el) => {
      el.addEventListener('input', () => {
        el.value = el.value.replace(numInputsRegEx, '');
      });
    });
  }
};
export {initNumInputs};
