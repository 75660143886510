const scrollTop = () => {
  
  var scrollToTopBtn = document.querySelector(".scrollToTopBtn");

  if(scrollToTopBtn) {
    document.addEventListener("scroll", handleScroll);
    
    function handleScroll() {}

    scrollToTopBtn.addEventListener("click", scrollToTop);
  
    function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }  
};

export {scrollTop};
