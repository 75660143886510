const sliders = document.querySelectorAll('.product-slider__wrap');

const initProductSlider = () => {
  if (!sliders) {
    return;
  }

  sliders.forEach((slider) => {
    let swiper = new Swiper(slider, {
      slidesPerView: 'auto',
      spaceBetween: 0,
      navigation: {
        nextEl: '.product-slider .button--next',
        prevEl: '.product-slider .button--prev',
      },
    });
  });
};

export {initProductSlider};

