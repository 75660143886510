import {closeButtonHandler} from './init-mobile-catalog';

const inputSearch = document.querySelector('input[name=search]');
const dropdown = document.querySelector('.search__dropdown');
const dropdownWrapper = document.querySelector('.search__dropdown-wrap');
const hiddenDropdown = 'search__dropdown--hidden';
const activeDropdown = 'search__dropdown-wrap--active';
const breakpoint = window.matchMedia('(max-width:959px)');

const initDropdown = () => {
  if (!inputSearch) {
    return;
  }

  // открытие подсказки поиск
  const openSearch = (preventScrollLock) => {
    dropdown.classList.remove(hiddenDropdown);
    dropdownWrapper.classList.add(activeDropdown);

    if (!preventScrollLock) {
      window.disableBodyScroll(dropdown, {
        reserveScrollBarGap: true,
      });
    }
  };

  // закрытие подсказки поиска
  const closeSearch = (preventScrollLock) => {
    dropdown.classList.add(hiddenDropdown);
    dropdownWrapper.classList.remove(activeDropdown);

    if (!preventScrollLock) {
      setTimeout(() => {
        window.enableBodyScroll(dropdown);
      }, 300);
    }
  };

  // закрытие подсказки поиска по клику вне блока
  window.addEventListener('click', () => {
    if (breakpoint.matches) {
      closeSearch();
    }
  });

  // закрытие подсказки поиска при потере фокуса с инпута
  inputSearch.addEventListener('blur', () => {
    if (breakpoint.matches) {
      return;
    }
    closeSearch();
  });

  // инициализация поиска
  inputSearch.addEventListener('keyup', () => {
    if (inputSearch.value === '') {
      closeSearch();
    } else {
      openSearch();
      closeButtonHandler();
    }
  });
};

export {initDropdown};
