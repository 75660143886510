const btns = document.querySelectorAll('.js-title-btn');

const initActiveBtn = () => {
  if (!btns) {
    return;
  }

  btns.forEach((btn) => {
    btn.addEventListener('click', () => {
      btn.forEach((item) => {
        item.classList.remove('is-active');
      });

      btn.classList.add('is-active');
    });
  });
};

export {initActiveBtn};
