const filterOpen = () => {

  let filter_btn = document.querySelector('.filter-btn');
  let filter_block = document.querySelector('.filter-block');
  let filter_cross = document.querySelector('.filter-block-cross');

  if (filter_btn && filter_block && filter_cross) {
    filter_btn.onclick = function() {
      filter_block.classList.toggle('open');
    };
  
    filter_cross.onclick = function() {
      filter_block.classList.remove('open');
    }
  }
};

export {filterOpen};
