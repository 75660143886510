const footerAccordions = document.querySelectorAll('.nav-footer');
const breakpointSm = window.matchMedia('(max-width: 639px)');

const closeMenuOnResize = (items) => {
  if (!breakpointSm.matches) {
    items.forEach((item) => {
      if (item.classList.contains('nav-footer__item--active')) {
        item.classList.toggle('nav-footer__item--active');
      }
    });
  }
};

const setupFooterAccordion = (accordion) => {
  const items = accordion.querySelectorAll('[data-accordion]');
  items.forEach((item) => {
    const title = item.querySelector('.nav-footer__title');
    title.addEventListener('click', (evt) => {
      if (!breakpointSm.matches) {
        return;
      }
      evt.preventDefault();
      item.classList.toggle('nav-footer__item--active');
    });
  });
  breakpointSm.addListener(() => closeMenuOnResize(items));
};

const initFooterAccordions = () => {
  if (!footerAccordions.length) {
    return;
  }
  footerAccordions.forEach((el) => {
    setupFooterAccordion(el);
  });
};

export {initFooterAccordions};
