import {closeModal} from './modals/modal';

const cards = document.querySelectorAll('.cart-popup');
const btns = document.querySelectorAll('.js-btn-cart');

const closePopup = (modal, parent, card) => {
  closeModal(modal);
  parent.insertAdjacentElement('beforeend', card);
};

const setUrl = (modal, el) => {
  const url = el.getAttribute('href');
  document.location.href = url;
  closeModal(modal);
};

const closeModalonSaveBtnClick = (btn, modal, parent, card) => {
  btn.classList.add('is-active');
  btn.addEventListener('click', () => {
    closePopup(modal, parent, card);
  });
};

const initCardPopup = () => {
  if (!cards) {
    return;
  }

  btns.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      const productCard = btn.closest('.product-card');
      const modal = document.querySelector('.modal--active');
      const parent = btn.closest('.product-slider__item');
      const card = parent.querySelector('.card-popup');
      const overlay = modal.querySelector('.modal__overlay');
      const closeBtn = modal.querySelector('.modal__close-btn');

      const value = productCard.querySelector('.js-num input').value;

      if (productCard.classList.contains('in-cart')) {
        setUrl(modal, btn);
      } else {
        e.preventDefault();
        btn.firstChild.textContent = btn.dataset.text;
        productCard.classList.add('in-cart');

        if (card) {
          modal.querySelector('.modal__content').insertAdjacentElement('afterbegin', card);

          const lists = modal.querySelectorAll('.card-popup__list');
          const btnsIcon = modal.querySelectorAll('.button--icon');

          lists.forEach((list) => {
            const inputs = list.querySelectorAll('input');
            inputs[0].checked = true;

            inputs.forEach((input) => {
              input.addEventListener('change', () => {
                const saveBtn = input.closest('.card-popup__pack').querySelector('.js-card-popup-btn');
                saveBtn.classList.add('is-active');

                closeModalonSaveBtnClick(saveBtn, modal, parent, card);
              });
            });
          });

          btnsIcon.forEach((item) => {
            item.addEventListener('click', () => {
              const saveBtn = item.closest('.card-popup__pack').querySelector('.js-card-popup-btn');
              saveBtn.classList.add('is-active');

              closeModalonSaveBtnClick(saveBtn, modal, parent, card);
            });
          });

          modal.querySelector('.js-num input').value = value;
          if (modal.querySelector('.button--minus').hasAttribute('disabled')) {
            modal.querySelector('.button--minus').disabled = false;
          }

          closeBtn.addEventListener('click', () => {
            closePopup(modal, parent, card);
          });

          overlay.addEventListener('click', () => {
            closePopup(modal, parent, card);
          });

          document.addEventListener('keydown', (evt) => {
            if (evt.key === 'Escape' || evt.key === 'Esc') {
              closePopup(modal, parent, card);
            }
          });
        } else {
          setTimeout(() => {
            window.enableBodyScroll(modal);
          }, 300);
          setTimeout(() => {
            closeModal(modal);
          }, 7000);
        }
      }
    });
  });
};

export {initCardPopup};
