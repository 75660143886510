const mobileMenu = document.querySelector('.mobile-menu');
const buttonCatalog = document.querySelector('.button--catalog');
const mobileCloseButton = document.querySelector('.mobile-menu > .close-button');
const breakpoint = window.matchMedia('(min-width:959px)');


const openMenu = (preventScrollLock) => {
  buttonCatalog.ariaPressed = 'true';
  mobileMenu.classList.add('active');

  buttonCatalog.classList.add('no-pointer');
  setTimeout(() => {
    buttonCatalog.classList.remove('no-pointer');
  }, 1000);

  if (!preventScrollLock) {
    window.disableBodyScroll(mobileMenu, {
      reserveScrollBarGap: true,
    });
  }
};

const closeMenu = (preventScrollLock) => {
  buttonCatalog.ariaPressed = 'false';
  mobileMenu.classList.remove('active');

  buttonCatalog.classList.add('no-pointer');
  setTimeout(() => {
    buttonCatalog.classList.remove('no-pointer');
  }, 1200);

  if (!preventScrollLock) {
    setTimeout(() => {
      window.enableBodyScroll(mobileMenu);
    }, 300);
  }
};

const closeMenuOnResize = () => {
  closeMenu();
};

const initMobileMenu = () => {
  if (!buttonCatalog || !mobileMenu) {
    return;
  }

  mobileCloseButton.addEventListener('click', () => {
    closeMenu();
  });

  buttonCatalog.addEventListener('click', () => {
    if (breakpoint.matches) {
      return;
    }

    if (buttonCatalog.ariaPressed === 'true') {
      closeMenu();
    } else {
      openMenu();
    }
  });

  breakpoint.addListener(closeMenuOnResize);
};

export {initMobileMenu, closeMenu};
