import {openModal} from './modals/modal';
import {closeModal} from './modals/modal';

const btn = document.querySelector('.js-callback-btn');
const modal = document.querySelector('.modal--callback-form');

const initCallbackForm = () => {
  if (!btn) {
    return;
  }

  btn.addEventListener('click', () => {
    const overlay = modal.querySelector('.modal__overlay');
    const closeBtn = modal.querySelector('.modal__close-btn');
    openModal(modal);

    closeBtn.addEventListener('click', () => {
      closeModal(modal);
    });

    overlay.addEventListener('click', () => {
      closeModal(modal);
    });

    document.addEventListener('keydown', (evt) => {
      if (evt.key === 'Escape' || evt.key === 'Esc') {
        closeModal(modal);
      }
    });
  });
};

export {initCallbackForm};
